<template>
  <div>
    <!-- Counter Widgets -->
    <a-row :gutter="24">
      <h2>User Information <a-spin v-if="confirmLoading" /></h2>
      <a-col
        v-for="(stat, index) in currentData"
        :key="index"
        :span="24"
        :lg="12"
        :xl="6"
        class="mb-24"
      >
        <!-- Widget 1 Card -->
        <WidgetCounter
          :title="stat.title"
          :value="stat.value"
          :prefix="stat.prefix"
          :suffix="stat.suffix"
          :icon="stat.icon"
          :status="stat.status"
        ></WidgetCounter>
        <!-- / Widget 1 Card -->
      </a-col>
    </a-row>

    <!-- / Counter Widgets -->
  </div>
</template>

<script>
import { sendRequest } from "@/http/axios.method";

// Counter Widgets
import WidgetCounter from "../components/Widgets/WidgetCounter";

export default {
  components: {
    WidgetCounter,
  },
  data() {
    return {
      config: {
        Authorization: "Bearer " + this.$store.state.authUser.token,
      },
      dashboardReports: [],
      alluser: [],
      todayData: [],
      activeUser: [],
      socialUser: [],
      wihoutSocialUser: [],
      currentMonth: [],
      previousMonth: [],
      confirmLoading: false,
    };
  },
  mounted() {
    this.dashboardReport();
  },
  methods: {
    dashboardReport() {
      this.confirmLoading = true;
      sendRequest("get", "admin/dashboard", {}, this.config)
        .then((response) => {
          this.confirmLoading = false;
          if (response.success) {
            let data = response.data;

            this.alluser = data.users;
            this.todayData = data.todayUser;
            this.activeUser = data.loggedUser;
            this.socialUser = data.socialUser;
            this.wihoutSocialUser = data.withoutSocialUser;
            this.this_month_user_joined = data.this_month_user_joined;
            this.last_month_user_joined = data.last_month_user_joined;

            // this.currentMonth = data[1];
            // this.previousMonth = data[2];
          } else {
            if (response.status_code === 401) {
              this.$store.dispatch("logout");
              this.$router.push("/login");
            }
            if (response.status_code === 500) {
              this.revenueReportData = [];
            }
          }
        })
        .catch(() => {
          this.confirmLoading = false;
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
  },
  computed: {
    currentData: function () {
      return [
        {
          title: "All Users",
          value: this.alluser,
          suffix: "",
          icon: `
						<svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z" fill="#111827"/>
							<path d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z" fill="#111827"/>
							<path d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z" fill="#111827"/>
							<path d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z" fill="#111827"/>
						</svg>`,
        },
        {
          title: "Social Login User",
          value: this.socialUser,
          suffix: "",
          icon: `
          <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="12" cy="7" r="4" fill="#1976D2" />
  <path d="M12 11C9.79 11 8 12.79 8 15V17H16V15C16 12.79 14.21 11 12 11Z" fill="#111827" />
</svg>
`,
        },
        {
          title: "Without Social Login User",
          value: this.wihoutSocialUser,
          suffix: "",
          icon: `
          <svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="12" cy="7" r="4" fill="#1976D2" />
  <path d="M12 11C9.79 11 8 12.79 8 15V17H16V15C16 12.79 14.21 11 12 11Z" fill="#111827" />
</svg>
`,
        },

        // {
        //   title: "Today Joined",
        //   value: this.todayData,
        //   prefix: "+",
        //   status: "danger",
        //   suffix: "",
        //   icon: `
        //   <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        // 			<path d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z" fill="#111827"/>
        // 			<path d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z" fill="#111827"/>
        // 			<path d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z" fill="#111827"/>
        // 			<path d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z" fill="#111827"/>
        // 		</svg>`,
        // },
        {
          title: "This Month Joined",
          value: this.this_month_user_joined,
          prefix: "+",
          status: "danger",
          suffix: "",
          icon: `
          <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z" fill="#111827"/>
							<path d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z" fill="#111827"/>
							<path d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z" fill="#111827"/>
							<path d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z" fill="#111827"/>
						</svg>`,
        },
        {
          title: "Last Month Joined",
          value: this.last_month_user_joined,
          prefix: "",
          status: "danger",
          suffix: "",
          icon: `
          <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z" fill="#111827"/>
							<path d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z" fill="#111827"/>
							<path d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z" fill="#111827"/>
							<path d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z" fill="#111827"/>
						</svg>`,
        },
        {
          title: "Today Active User",
          value: this.activeUser,
          prefix: "+",
          status: "danger",
          suffix: "",
          icon: `
        	<svg width="22" height="22" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <!-- User Silhouette -->
  <path d="M12 2C14.2091 2 16 3.79086 16 6C16 8.20914 14.2091 10 12 10C9.79086 10 8 8.20914 8 6C8 3.79086 9.79086 2 12 2Z" fill="#111827"/>
  <path d="M8 14C4.68629 14 2 16.6863 2 20V22H22V20C22 16.6863 19.3137 14 16 14H8Z" fill="#111827"/>
  <!-- Circle Indicator -->
  <circle cx="18" cy="6" r="3" fill="#4CAF50"/>
</svg>
`,
        },
      ];
    },
    monthlyData: function () {
      return [this.currentMonth, this.previousMonth];
    },
  },
};
</script>

<style lang="scss"></style>
